<template>
  <div class="question">
      <div class="row">
        <div class="col-md-3">{{ assignedActivities.activity }}</div>
        <div class="col-md-3">{{ assignedActivities.id_activity }}</div>
        <div class="col-md-3">
        <ul>
          <li v-for="professor in assignedActivities.professors" :key="professor">{{ professor }}</li>
        </ul>
      </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'AssignedActivitiesCard',
  props: ['assignedActivities'],
  computed: {
      tracker () {
          const tracker = this.$utils.cloneObj(this.assignedActivities)
          return tracker
      },
  }    
}
</script>

<style>
.row {
display: flex;
width: 100%;
}

.header {
display: flex;
font-weight: bold;
margin-bottom: 15px;
}

.col-md-3 {
flex: 1;
margin-right: 10px; 
text-align: center;
}
</style>
