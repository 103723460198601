<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('Assignedactivities.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('Assignedactivities.title')}}</template>
    <template #subtitle>{{$t('Assignedactivities.subtitle')}}</template>

    <section class="button-container">
      <div class="button-group">
        <button class="btn btn--primary btn--outline" @click="downloadUsersCSV">{{$t('Assignedactivities.downloadTeachers')}}</button>
        <button class="btn btn--primary btn--outline" @click="downloadActivitiesCSV">{{$t('Assignedactivities.downloadActivities')}}</button>
        <button class="btn btn--primary btn--outline" @click="downloadTemplateCSV">{{$t('Assignedactivities.downloadTemplate')}}</button>
      </div>
      <div class="button-center">
        <button class="btn btn--primary" @click="openFilePicker">{{$t('Assignedactivities.uploadCsv')}}</button>
        <div class="btn-wrapper" v-if="errorCSVFileName">
          <span class="btn-separator">{{ $t('Assignedactivities.downloadErrorsMessage') }}</span>
          <button class="btn btn--primary btn--link btn--xs" @click="downloadFile(this.errorCSVFileName, this.filename)">{{ $t('Assignedactivities.downloadErrors') }}</button>
        </div>
        <div class="btn-wrapper" v-else-if="successMessage">
          <span class="btn-separator">{{ $t('Assignedactivities.successMsg') }}</span>
        </div>
      </div>
    </section>
    <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" accept=".csv">


    <section class="main-section">
      <div class="search">
        <div class="search__group">
          <div class="ico-search"><img src="@/assets/images/icons/ico-search--black.svg"></div>
          <form class="search_form" @submit.prevent="performSearch()">
            <form-input v-if="$i18n.locale == 'es'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Buscar" @input="performSearch()"></form-input>
            <form-input v-else-if="$i18n.locale == 'en'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Search" @input="performSearch()"></form-input>
          </form>
        </div>
        <div class="select">
          <div class="form__group">
            <select class="form__input" v-model.trim="searchOption">
              <option value="" disabled selected>{{$t('Assignedactivities.searchOptions')}}</option>
              <option value="activity">{{$t('Assignedactivities.titleActivity')}}</option>
              <option value="id_activity">{{$t('Assignedactivities.idActivity')}}</option>
              <option value="professors">{{$t('Assignedactivities.teacher')}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="box box--sm box--no-shadow">
        <div class="question">
          <div class="header">
            <div class="col-md-3">{{$t('Assignedactivities.titleActivity')}}</div>
            <div class="col-md-3">{{$t('Assignedactivities.idActivity')}}</div>
            <div class="col-md-3">{{$t('Assignedactivities.teacher')}}</div>
          </div>
        </div>
        <div class="question-list question-list--sm" v-for="assignedActivities in assignedActivities" :key="assignedActivities.id">
          <assigned-activities-card :assignedActivities="assignedActivities" />
        </div>
      </div>
    </section>
  </base-view-layout>
</template>

<script>
import { inject, reactive } from 'vue'
import BaseViewLayout from '@/components/BaseViewLayout'
import AssignedActivitiesCard from '@/components/AssignedActivitiesCard'
import FormInput from '@/components/FormInput'

export default {
  name: 'AssignedActivities',
  components: { BaseViewLayout, AssignedActivitiesCard, FormInput },
  setup() {
    const axios = inject('$axios')
    const output = {
      assignedActivities: reactive([]),
      axios,
      searchTerm: '',
      searchOption: '',
      selectedFile: null,
      filename: 'assignment_errors'
    }

    async function loadAssignedActivities() {
      try {
        const response = await axios.get('assigned-activities');
        output.assignedActivities.splice(0);
        output.assignedActivities.push(...response.data.activities);
      } catch (error) {
        console.error(error);
      }
    }

    axios
      .get('assigned-activities')
      .then((res) =>
        res.data.activities.forEach((assignedActivities) =>
          output.assignedActivities.push(assignedActivities)
        )
      )
    return {
      ...output,
      loadAssignedActivities
    };
  },
  data () {
    return {
    successMessage: false,
    errorCSVFileName: ''
    }
  },
  methods: {
    async performSearch() {
      const filter = this.normalizeString(this.searchTerm);
      const attributeToSearch = this.searchOption;

      this.assignedActivities.splice(0);
      this.assignedActivities.push(
        ...(await this.$axios.get('assigned-activities')).
        data.activities
      );

      if (filter !== '' && attributeToSearch !== '') {
        const search = this.assignedActivities.filter(object => {
          const attributeValue = this.normalizeString(object[attributeToSearch].toString());
          return attributeValue.includes(filter);
        });
        this.assignedActivities.splice(0);
        this.assignedActivities.push(...search);
      } 
      else if (filter !== '') {
        const noattributeSelection = 'activity';
        const search = this.assignedActivities.filter(object => {
          const attributeValue = this.normalizeString(object[noattributeSelection].toString());
          return attributeValue.includes(filter);
        });
        this.assignedActivities.splice(0);
        this.assignedActivities.push(...search);
      }
    },
    normalizeString(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    },
    async downloadUsersCSV() {
      try {
        const response = await this.$axios.get('download-data/users', {
          responseType: 'blob'
        });
        this.downloadFile(response.data, 'Profesores Math with Meaning - ' + new Date().toLocaleDateString() + '.csv');
      } catch (error) {
        console.error(error);
      }
    },
    async downloadActivitiesCSV() {
      try {
        const response = await this.$axios.get('download-data/activities', {
          responseType: 'blob'
        });
        this.downloadFile(response.data, 'Actividades Math with Meaning - ' + new Date().toLocaleDateString() + '.csv');
      } catch (error) {
        console.error(error);
      }
    },
    downloadFile(data, filename) {
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    downloadTemplateCSV() {
      const link = document.createElement('a');
      link.href = '/csv/Template_Ejemplo_CSV_Asignación_Actividades.csv';
      link.download = 'Template_Ejemplo_CSV_Asignación_Actividades.csv';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openFilePicker() {
      const fileInput = this.$refs.fileInput;
      fileInput.value = null;
      fileInput.addEventListener('change', this.handleFileChange);
      fileInput.click();
    },
    async handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      event.target.removeEventListener('change', this.handleFileChange);
      await this.processCSVFile(this.selectedFile);
    },
    processCSVFile(file) {
      const reader = new FileReader();
      reader.onload = event => {
        const csvContent = event.target.result;
        this.sendCSVDataToBackend(csvContent);
      };
      reader.readAsText(file);
    },
    sendCSVDataToBackend(csvContent) {
      this.successMessage = false;

      this.$axios.post('assigned-activities/', { csvContent })
        .then(response => {
          if(response.status == 200){
          if (response.data) {
            if(response.data.success) {
              this.errorCSVFileName = ''
              this.successMessage = true;
              this.loadAssignedActivities();
            } else {
              this.errorCSVFileName = response.data;
              this.loadAssignedActivities();
            }
          }
        }
        })
        .catch(error => {
          console.log(error);
        });
    },  
  }
}
</script>

<style>
.search {
  width: 100%;
  display: flex;
}

.search__group {
  width: 90%;
  box-sizing: border-box;
}

.select {
  width: 30%;
  margin-top: 5px;
  box-sizing: border-box;
}
.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-width: 1000px !important;
  margin-bottom: 10px;
}
.button-group button {
  width: calc(33.33% - 10px) !important;
  margin-bottom: 10px;
}
.button-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.btn-wrapper {
  margin-left: 10px;
  width: 30%;
  display: inline-block;
}
.success-message{
    margin-left: 1rem;
}
@media (max-width: 1080px) {
  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .button-group button {
    width: 100%;
    margin-bottom: 10px;
  }

  .button-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
}
</style>
